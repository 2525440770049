import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false
    },
    dense: {
      type: Boolean,
      required: false
    },
    dialogWidth: {
      type: Number,
      default: 768
    }
  }
};